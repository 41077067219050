html,
body {
	margin: 0;
	padding: 0;
}
body {
	color: #292929;
	font: 90% Roboto, Arial, sans-serif;
	font-weight: 300;
}
body {
    overflow-y: scroll; /* will always show vertical scrollbars */
}
div#container {
	background-color: #fffffff1;
}
div#extra {
	display: flex;
	padding: 0px;
	margin: auto;
}
div#extra #card {
	width: 25rem;
	height: 25rem;
	margin: 2%;
	padding: 5px;
	border: 2px solid #292929;
	position: relative;
	overflow: hidden;
}
div#extra #card #graph1{
    width: 100%;   
    height: 100%;  
    position: relative;
    z-index: 10;
}
div#footer-wrapper{
	background: #dcdedc;
}
#footer1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
	padding-left: 50px;
	padding-right: 50px;
    border-top: 1px solid rgb(203, 203, 203);
    font-family: "Old Standard TT";
}

#footer1 .left {
    text-align: left;
    flex: 1;
	padding-left: 1px;
	color: #767676;
	font-size: 15px;
}

#footer1 .right {
	color: #767676;
    text-align: right;
    flex: 1;
	padding-right: 10px;
	font-size: 15px;
}
hr#custom-hr {
	margin-left: 45px;
	margin-right: 45px;
}
div#footer2 {
	color: #767676;
	text-align: left;
	font-family: "Old Standard TT";
	padding-bottom: 10px;
	padding-left: 50px;
	font-size: 15px;
}
div#wrapper {
	float: left;
	width: 60;
	margin-left: 5%;
}
div#wrapper#content {
	text-align: center;
}
div#content h1 {
	font-size: 30px;
	font-weight: 700;
	color: rgb(11, 49, 11);
}
div#content #reference-text{
	font-size: 18px;
}
div#content button {
	height: 50px;
	width: 10rem;
	background-color: rgb(31, 163, 58);
	color: #fff;
	border: none;
	border-radius: 10px;
	margin: 20px;
}
div#navigation img {
	width: 30rem;
	height: 22rem;
	margin: 6px;
}
div#extra {
	clear: both;
	width: 100%;
}
.box {
    width: 600px;
	height: 40px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
}

.box form .input_box {
	border:#000;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
	height: 50px;
}
.box form .input_box input {
    width: 100%;
    font-size: 25px;
    padding: 5px 15px;
    border:hsl(from color h s l);
    outline: none;
	height: 40px;
	border-radius: 5px;
}
.box form .search-bar {
    width: 100%;
    display: flex;
    position: relative;
	height: 50px;
}
.box form .selection {
    background-color: #F3CA52;
    padding: 5px 10px;  /* Adjust padding to fit within 40px height */
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;  /* Set height to 40px */
}

.box form .selection p {
    font-size: 25px;
    color: #fff;
    margin: 0; /* Add this line to remove extra space around the text */
}

.box form .selection span {
    border-right: 5px solid #fff;
    border-bottom: 5px solid #fff;
    border-left: 5px solid #000;
    border-top: 5px solid #000;
    display: inline-block;
    rotate: 45deg;
    margin-left: 10px;
    margin-bottom: 5px;
}

.box form .categories {
	width: 110px;
    position: absolute;
    top: 100%;
    right: 0px;
    background-color: #F3CA52;
    color: #fff;
    padding: 5px;
    font-size: 25px; /* Add 'px' to specify the font size */
    margin-top: 1px;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
}

.box form .categories p:hover {
    background-color: #626eb3;
    cursor: pointer;
}

.box form .active {
    opacity: 1;
    pointer-events: fill;
}
.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #7ABA78;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}
