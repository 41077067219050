html,
body {
	margin: 0;
	padding: 0;
}
body {
	color: #292929;
	font: 90% Roboto, Arial, sans-serif;
	font-weight: 300;
}
body {
    overflow-y: scroll; /* will always show vertical scrollbars */
  }
div#header {
	position: relative;
	border-radius: 0px;
}
div#header h1 {
	height: 80px;
	line-height: 80px;
	margin: 0;
	padding-left: 10px;
	background: #0A6847;
	color: white;
	margin-left: 1%;
	font-size:xx-large;
    padding-right: 50px;
}
div#header nav {
	position: relative;
	display: flex;
    justify-content: space-around;
	list-style: none;
	margin: 0;
	padding-left: 10px;
	background: #0A6847;
	color: white;
}
div#header li {
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	padding-right: 100px;
	color: white;
}
div#header li a {
	margin: 0;
	background: #0A6847;
	color: white;
	font-size: large;
	font-weight: bold;
    text-decoration: none;
}
div#extra {
	display: flex;
	padding: 0px;
	margin: auto;
}
div#footer {
	background: #42444e;
	color: #fff;
}
div#footer p {
	padding: 20px 10px;
	text-align: center;
}
div#wrapper {
	float: left;
	width: 60;
	margin-left: 5%;
}
div#wrapper#content {
	text-align: center;
}
div#content h1 {
	font-size: 30px;
	font-weight: 700;
	color: rgb(11, 49, 11);
}
div#content button {
	height: 50px;
	width: 10rem;
	background-color: rgb(31, 163, 58);
	color: #fff;
	border: none;
	border-radius: 10px;
	margin: 20px;
}
div#extra {
	clear: both;
	width: 100%;
}
.box {
    width: 600px;
	height: 40px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
}

.box form .input_box {
	border:#000;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
	height: 50px;
}
.box form .input_box input {
    width: 100%;
    font-size: 25px;
    padding: 5px 15px;
    border:hsl(from color h s l);
    outline: none;
	height: 40px;
	border-radius: 5px;
}
.box form .search-bar {
    width: 100%;
    display: flex;
    position: relative;
	height: 50px;
}
.box form .selection {
    background-color: #B99470;
    padding: 5px 10px;  /* Adjust padding to fit within 40px height */
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;  /* Set height to 40px */
}

.box form .selection p {
    font-size: 25px;
    color: #fff;
    margin: 0; /* Add this line to remove extra space around the text */
}

.box form .selection span {
    border-right: 5px solid #fff;
    border-bottom: 5px solid #fff;
    border-left: 5px solid #000;
    border-top: 5px solid #000;
    display: inline-block;
    rotate: 45deg;
    margin-left: 10px;
    margin-bottom: 5px;
}

.box form .categories {
	width: 110px;
    position: absolute;
    top: 100%;
    right: 0px;
    background-color: #B99470;
    color: #fff;
    padding: 5px;
    font-size: 25px; /* Add 'px' to specify the font size */
    margin-top: 1px;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
}

.box form .categories p:hover {
    background-color: #d38134;
}

.box form .active {
    opacity: 1;
    pointer-events: fill;
}
.graph-container {
    width: 90vw;   /* 90% of viewport width */
    height: 80vh;  /* 80% of viewport height */
    position: relative;
    z-index: 10;
}